(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/co-op-play/assets/javascripts/middlewares/co-op-play-middleware.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/co-op-play/assets/javascripts/middlewares/co-op-play-middleware.js');
"use strict";

const DEBOUNCE_TIMEOUT = 600;
const {
  fetchRetailersAction
} = svs.components.marketplaceShared.coOpPlay.actions;
let timer = null;
const coOpPlayMiddleware = store => next => action => {
  next(action);
  const {
    FRACTION_PAYMENT_SUCCESS,
    FRACTION_PAYMENT_FAILURE
  } = svs.components.marketplaceShared.sharedConstants.constants;
  const {
    FILTER_UPDATE
  } = svs.components.marketplaceShared.coOpPlay.actions;
  const interestingActions = [FILTER_UPDATE, FRACTION_PAYMENT_SUCCESS, FRACTION_PAYMENT_FAILURE];
  if (interestingActions.indexOf(action.type) === -1) {
    return;
  }
  if (timer !== null) {
    clearTimeout(timer);
    timer = null;
  }
  if (timer === null) {
    timer = setTimeout(() => {
      timer = null;

      store.dispatch(fetchRetailersAction());
    }, DEBOUNCE_TIMEOUT);
  }
};
setGlobal('svs.components.marketplaceShared.coOpPlay.middlewares.coOpPlayMiddleware', coOpPlayMiddleware);

 })(window);