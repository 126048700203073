(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/co-op-play/assets/javascripts/helpers/query-filter.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/co-op-play/assets/javascripts/helpers/query-filter.js');
"use strict";


const {
  productIds,
  resolveProductId
} = svs.utils.products;
function productFilter(filter) {
  const filterProductIds = filter.products.filter(product => {
    var _product;
    return !((_product = {}[product]) !== null && _product !== void 0 && _product.length);
  }).map(product => resolveProductId(product));
  const productsQuery = filterProductIds.map(productId => {
    if (productId === productIds.T8) {
      return [productIds.T8STRYK, productIds.T8EXTRA, productIds.T8];
    }
    return "".concat(productId);
  }, []);
  if (productsQuery.length) {
    return "productIds=".concat(productsQuery.join(','));
  }
  return null;
}
function rangeFilter(filter) {
  const filterParams = [];
  if (filter.fractionPriceMin !== filter.fractionPriceMinDisplay) {
    filterParams.push("sharePriceMin=".concat(filter.fractionPriceMin));
  }
  if (filter.fractionPriceMax !== filter.fractionPriceMaxDisplay) {
    filterParams.push("sharePriceMax=".concat(filter.fractionPriceMax));
  }
  if (filter.fractionCountMin !== filter.fractionCountMinDisplay) {
    filterParams.push("maxSharesMin=".concat(filter.fractionCountMin));
  }
  if (filter.fractionCountMax !== filter.fractionCountMaxDisplay) {
    filterParams.push("maxSharesMax=".concat(filter.fractionCountMax));
  }
  if (filter.systemPriceMin !== filter.systemPriceMinDisplay) {
    filterParams.push("totalDrawAmountMin=".concat(filter.systemPriceMin));
  }
  if (filter.systemPriceMax !== filter.systemPriceMaxDisplay) {
    filterParams.push("totalDrawAmountMax=".concat(filter.systemPriceMax));
  }
  return "".concat(filterParams.join('&'));
}
const queryFilter = filter => {
  let searchQuery;
  if (filter.searchName) {
    searchQuery = "query=".concat(encodeURIComponent(filter.searchName));
  }
  const productsQuery = productFilter(filter);
  const rangeQuery = rangeFilter(filter);
  return [searchQuery, productsQuery, rangeQuery].filter(Boolean).join('&');
};
setGlobal('svs.components.marketplaceShared.coOpPlay.helpers.queryFilter', queryFilter);

 })(window);