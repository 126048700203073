(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/co-op-play/assets/javascripts/co-op-result-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/co-op-play/assets/javascripts/co-op-result-actions.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  services
} = svs.components.marketplaceShared.coOpPlay;
const {
  dialogWrapper
} = svs.components.marketplaceShared;
const {
  merge
} = svs.components.marketplaceShared.coOpPlay.helpers.utils;
const {
  errorCodeHandler
} = svs.components.marketplaceData;
const {
  COOP_PLAY_FETCH_INIT,
  COOP_PLAY_FETCH_FAILURE,
  COOP_PLAY_FETCH_SUCCESS,
  COOP_PLAY_FETCH_NEXT_INIT,
  COOP_PLAY_FETCH_NEXT_SUCCESS,
  COOP_PLAY_FETCH_NEXT_FAILURE
} = svs.components.marketplaceShared.sharedConstants.constants;
const fetchRetailersAction = () => async (dispatch, getState) => {
  const currentFilter = getState().CoOpPlayFilter;
  const filter = _objectSpread(_objectSpread({}, currentFilter), {}, {
    products: Object.keys(currentFilter.products).filter(p => currentFilter.products[p])
  });
  let follow = {};
  let games = [];
  const recursiveFetchOtherRetailers = async (offset, count) => {
    let response = await new Promise(resolve => {
      services.fetchResults(filter, offset || 0, (error, results) => {
        if (error) {
          errorCodeHandler.getErrorMessage(error.code, err => resolve({
            error: err.message
          }));
        } else {
          resolve({
            results
          });
        }
      }, {
        count
      });
    });
    if (response.error) {
      dispatch({
        type: COOP_PLAY_FETCH_FAILURE,
        other: {
          error: response.error
        }
      });
    } else {
      games = merge(games, response.results.games || []);
      const numFollowedInResult = response.results.games.filter(game => games.find(g => g.id === game.id && g.following)).length;
      const nextOffset = (offset || 0) + response.results.count;
      if (numFollowedInResult > 0 && nextOffset < response.results.totalCount) {
        response = await recursiveFetchOtherRetailers(nextOffset, numFollowedInResult);
      }
    }
    return response;
  };
  dispatch({
    type: COOP_PLAY_FETCH_INIT
  });
  if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER) && !filter.searchName) {
    var _follow;
    follow = await new Promise(resolve => {
      services.fetchGroups(filter, 0, (error, results) => {
        if (error) {
          errorCodeHandler.getErrorMessage(error.code, err => resolve({
            error: err.message
          }));
        } else {
          resolve({
            results
          });
        }
      });
    });
    games = merge(games, ((_follow = follow) === null || _follow === void 0 || (_follow = _follow.results) === null || _follow === void 0 ? void 0 : _follow.games) || []);
  }
  if (follow.error) {
    dispatch({
      type: COOP_PLAY_FETCH_FAILURE,
      follow: {
        error: follow.error
      }
    });
  }
  const other = await recursiveFetchOtherRetailers();
  if (!other.error && !follow.error) {
    dispatch(_objectSpread({
      type: COOP_PLAY_FETCH_SUCCESS,
      games,
      other: {
        count: Number(other.results.count),
        offset: Number(other.results.offset),
        totalCount: Number(other.results.totalCount)
      }
    }, follow.results ? {
      follow: {
        count: Number(follow.results.count),
        offset: Number(follow.results.offset),
        totalCount: Number(follow.results.totalCount)
      }
    } : {}));
  }
};
const fetchRetailersNextAction = isFollow => async (dispatch, getState) => {
  const {
    follow: followState,
    other: otherState
  } = getState().CoOpPlayResults;
  const {
    offset,
    totalCount
  } = isFollow ? followState : otherState;
  const service = isFollow ? services.fetchGroups : services.fetchResults;
  if (offset >= totalCount) {
    return;
  }
  const currentFilter = getState().CoOpPlayFilter;
  const filter = _objectSpread(_objectSpread({}, currentFilter), {}, {
    products: Object.keys(currentFilter.products).filter(p => currentFilter.products[p])
  });
  dispatch({
    type: COOP_PLAY_FETCH_NEXT_INIT
  });
  const {
    error,
    results
  } = await new Promise(resolve => {
    service(filter, offset, (error, results) => resolve({
      error,
      results
    }));
  });
  if (error) {
    dispatch({
      type: COOP_PLAY_FETCH_NEXT_FAILURE,
      error: error.message
    });
    dispatch(dialogWrapper.actions.showDialog({
      title: 'Kunde inte hämta fler',
      type: 'error'
    }));
  } else {
    const key = isFollow ? 'follow' : 'other';
    dispatch({
      type: COOP_PLAY_FETCH_NEXT_SUCCESS,
      games: results.games,
      [key]: {
        count: results.count,
        offset: results.offset,
        totalCount: results.totalCount
      }
    });
  }
};
setGlobal('svs.components.marketplaceShared.coOpPlay.actions', {
  fetchRetailersAction,
  fetchRetailersNextAction
});

 })(window);