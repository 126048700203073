(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/co-op-play/assets/javascripts/reducers/co-op-play-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/co-op-play/assets/javascripts/reducers/co-op-play-reducer.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  COOP_PLAY_FETCH_INIT,
  COOP_PLAY_FETCH_FAILURE,
  COOP_PLAY_FETCH_SUCCESS,
  COOP_PLAY_FETCH_NEXT_INIT,
  COOP_PLAY_FETCH_NEXT_SUCCESS,
  COOP_PLAY_FETCH_NEXT_FAILURE,
  FRACTION_PAYMENT_SUCCESS
} = svs.components.marketplaceShared.sharedConstants.constants;
const {
  merge
} = svs.components.marketplaceShared.coOpPlay.helpers.utils;
const initialState = {
  data: [],
  follow: {
    error: null,
    offset: 0,
    totalCount: 0
  },
  loading: false,
  other: {
    error: null,
    offset: 0,
    totalCount: 0
  }
};
const reducer = function () {
  var _action$follow, _action$other;
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case COOP_PLAY_FETCH_INIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    case COOP_PLAY_FETCH_NEXT_INIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        follow: _objectSpread(_objectSpread({}, state.follow), {}, {
          error: null
        }),
        loading: true,
        other: _objectSpread(_objectSpread({}, state.other), {}, {
          error: null
        })
      });
    case COOP_PLAY_FETCH_FAILURE:
    case COOP_PLAY_FETCH_NEXT_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        follow: _objectSpread(_objectSpread({}, state.follow), {}, {
          error: (action === null || action === void 0 || (_action$follow = action.follow) === null || _action$follow === void 0 ? void 0 : _action$follow.error) || state.follow.error
        }),
        loading: false,
        other: _objectSpread(_objectSpread({}, state.other), {}, {
          error: (action === null || action === void 0 || (_action$other = action.other) === null || _action$other === void 0 ? void 0 : _action$other.error) || state.error
        })
      });
    case COOP_PLAY_FETCH_SUCCESS:
      {
        var _action$follow2;
        return _objectSpread(_objectSpread({}, state), {}, {
          data: action.games,
          follow: _objectSpread(_objectSpread({}, state.follow), {}, {
            error: null,
            offset: action.follow ? action.follow.offset + action.follow.count : state.offset,
            totalCount: (action === null || action === void 0 || (_action$follow2 = action.follow) === null || _action$follow2 === void 0 ? void 0 : _action$follow2.totalCount) || state.follow.totalCount
          }),
          loading: false,
          other: _objectSpread(_objectSpread({}, state.other), {}, {
            error: null,
            offset: action.other.offset + action.other.count,
            totalCount: action.other.totalCount
          })
        });
      }
    case COOP_PLAY_FETCH_NEXT_SUCCESS:
      {
        var _action$follow3, _action$follow4, _action$other2, _action$other3;
        if (action.other.offset !== state.other.offset && (action === null || action === void 0 ? void 0 : action.follow.offset) !== state.follow.offset) {
          return state;
        }
        return _objectSpread(_objectSpread({}, state), {}, {
          data: merge(state.data, action.games),
          follow: _objectSpread(_objectSpread({}, state.follow), {}, {
            error: null,
            offset: state.follow.offset + (action === null || action === void 0 || (_action$follow3 = action.follow) === null || _action$follow3 === void 0 ? void 0 : _action$follow3.count) || state.follow.offset,
            totalCount: (action === null || action === void 0 || (_action$follow4 = action.follow) === null || _action$follow4 === void 0 ? void 0 : _action$follow4.totalCount) || state.follow.totalCount
          }),
          loading: false,
          other: _objectSpread(_objectSpread({}, state.other), {}, {
            error: null,
            offset: state.other.offset + (action === null || action === void 0 || (_action$other2 = action.other) === null || _action$other2 === void 0 ? void 0 : _action$other2.count) || state.other.offset,
            totalCount: (action === null || action === void 0 || (_action$other3 = action.other) === null || _action$other3 === void 0 ? void 0 : _action$other3.totalCount) || state.other.totalCount
          })
        });
      }
    case FRACTION_PAYMENT_SUCCESS:
      {
        const groupIndex = state.data.findIndex(g => g.id === action.groupId);
        if (groupIndex < 0) {
          return state;
        }
        const games = state.data[groupIndex].games || [];
        const game = games.find(game => game.activatedDrawId === action.activatedDrawId) || {};
        const gameIndex = games.indexOf(game);
        const updatedSoldFractions = (game.soldFractions || 0) + action.fractionsBought;
        const updatedCurrentUserNumberOfShares = (game.currentUserNumberOfShares || 0) + action.fractionsBought;
        const updatedGame = _objectSpread(_objectSpread({}, game), {}, {
          currentUserNumberOfShares: updatedCurrentUserNumberOfShares,
          soldFractions: updatedSoldFractions,
          isSoldOut: updatedSoldFractions === game.maxFractions
        });
        const updatedGroup = _objectSpread(_objectSpread({}, state.data[groupIndex]), {}, {
          games: [...games.slice(0, gameIndex), updatedGame, ...games.slice(gameIndex + 1)]
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          data: [...state.data.slice(0, groupIndex), updatedGroup, ...state.data.slice(groupIndex + 1)]
        });
      }
    default:
      return state;
  }
};
setGlobal('svs.components.marketplaceShared.coOpPlay.reducers.CoOpPlayResultsReducer', reducer);

 })(window);