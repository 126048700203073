(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/co-op-play/assets/javascripts/middlewares/search-query-middleware.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/co-op-play/assets/javascripts/middlewares/search-query-middleware.js');

'use strict';

let debounceTimer;
const {
  FILTER_QUERY_NAME,
  QS_NAME,
  QS_PRODUCT,
  QS_FRACTION_DATA,
  COOP_PLAY_FETCH_INIT,
  COOP_PLAY_FETCH_SUCCESS
} = svs.components.marketplaceShared.sharedConstants.constants;
const {
  FILTER_UPDATE
} = svs.components.marketplaceShared.coOpPlay.actions;
const {
  queryString
} = svs.components.marketplaceShared.coOpPlay.helpers;
const searchQueryMiddleware = () => next => action => {
  var _action$updatedFilter;
  next(action);
  const interestingActions = [FILTER_UPDATE, COOP_PLAY_FETCH_INIT, COOP_PLAY_FETCH_SUCCESS];
  if (interestingActions.indexOf(action.type) === -1) {
    return;
  }
  const qs = queryString.querystringHandler(action);
  qs.delete(QS_PRODUCT);
  qs.delete(QS_FRACTION_DATA);
  const qsString = qs.toString();
  if (window.location.pathname === "".concat(svs.core.urlMapping.get('fulltraffHome'), "/andelsspel") || window.location.pathname === "".concat(svs.core.urlMapping.get('stryktipsetHome'), "/andelsspel") || window.location.pathname === "".concat(svs.core.urlMapping.get('europatipsetHome'), "/andelsspel") || window.location.pathname === "".concat(svs.core.urlMapping.get('powerplayHome'), "/andelsspel") || window.location.pathname === "".concat(svs.core.urlMapping.get('topptipsetHome'), "/andelsspel") || window.location.pathname === "".concat(svs.core.urlMapping.get('bombenHome'), "/andelsspel") || window.location.pathname === "".concat(svs.core.urlMapping.get('challengeHome'), "/andelsspel")) {
    if (qsString) {
      history.replaceState(null, null, "?".concat(qsString));
    } else if (qsString === '') {
      history.replaceState(null, null, window.location.pathname);
    }
  }
  if ((action === null || action === void 0 || (_action$updatedFilter = action.updatedFilter) === null || _action$updatedFilter === void 0 ? void 0 : _action$updatedFilter.key) === FILTER_QUERY_NAME) {
    var _action$updatedFilter2;
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    if ((action === null || action === void 0 || (_action$updatedFilter2 = action.updatedFilter) === null || _action$updatedFilter2 === void 0 ? void 0 : _action$updatedFilter2.key) === FILTER_QUERY_NAME) {
      if (action.updatedFilter.value === '') {
        qs.delete(QS_NAME);
      } else {
        qs.set(QS_NAME, action.updatedFilter.value.name);
      }
    }
    const searchTerm = qs.get(QS_NAME);
    if (!searchTerm) {
      next(action);
    }
  }
};
setGlobal('svs.components.marketplaceShared.coOpPlay.middlewares.searchQueryMiddleware', searchQueryMiddleware);

 })(window);