(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/co-op-play/assets/javascripts/helpers/status.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/co-op-play/assets/javascripts/helpers/status.js');
"use strict";


const ActivatedDrawStatus = Object.freeze({
  UNDEFINED: 0,
  PREPARED: 1,
  OPEN: 2,
  BUYIN_CLOSED: 3,
  CLOSED: 4,
  REFUNDING: 5,
  REFUNDING_COMISSION: 6
});
const GroupType = Object.freeze({
  UNDEFINED: 0,
  PRIVATE: 1,
  PUBLIC: 2,
  RETAILER: 3,
  SVS_TO_CUSTOMER: 4
});
const GroupStatus = Object.freeze({
  UNDEFINED: 0,
  ACTIVE: 1,
  INACTIVE: 2,
  ENDED: 3,
  PENDING_REMOVAL: 4,
  CREATED: 5,
  PUBLISHED: 6,
  UNPUBLISHED: 7
});
const GroupStatusReason = Object.freeze({
  UNDEFINED: 0,
  NO_REASON: 1,
  INVESTIGATION: 2,
  GROUP_ADMIN: 3,
  SVS_P_ADMIN: 4
});
const MemberRole = Object.freeze({
  UNDEFINED: 0,
  OWNER: 1,
  ADMIN: 2,
  MEMBER: 3
});
const MemberStatus = Object.freeze({
  UNDEFINED: 0,
  ACTIVE: 1,
  PENDING_REMOVAL: 2,
  INACTIVE: 3
});
setGlobal('svs.components.marketplaceShared.coOpPlay.helpers.status', {
  ActivatedDrawStatus,
  GroupType,
  GroupStatus,
  GroupStatusReason,
  MemberRole,
  MemberStatus
});

 })(window);