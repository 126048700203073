(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/co-op-play/assets/javascripts/helpers/normalize-team-with-games.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/co-op-play/assets/javascripts/helpers/normalize-team-with-games.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  getProductClassName
} = svs.utils.products;



const isSoldOut = draw => {
  const exists = ['soldShares', 'maxShares'].every(key => Object.keys(draw).includes(key));
  return exists ? draw.soldShares === draw.maxShares : false;
};
const normalizeGame = (groupId, activatedDraw) => _objectSpread(_objectSpread(_objectSpread({
  activatedDrawId: activatedDraw.activatedDrawId,
  containerId: activatedDraw.containerId,
  currentUserNumberOfShares: activatedDraw.currentUserNumberOfShares,
  groupId,
  name: activatedDraw.name,
  maxFractions: activatedDraw.maxShares,
  productName: getProductClassName(activatedDraw.productId),
  productId: activatedDraw.productId,
  wagerSerial: null,
  drawNumber: activatedDraw.drawNumber,
  templateId: activatedDraw.containerId,
  totalBalance: activatedDraw.totalDrawAmount,
  fractionPrice: activatedDraw.sharePrice,
  buyStop: new Date(activatedDraw.buyinCloseTime),
  drawCloseTime: activatedDraw.regClose,
  status: activatedDraw.activatedDrawStatus,
  activatedDrawStatus: activatedDraw.activatedDrawStatus,
  subProductId: activatedDraw.subProductId || null
}, activatedDraw.editorName ? {
  ownerName: activatedDraw.editorName
} : {}), activatedDraw.soldShares ? {
  soldFractions: activatedDraw.soldShares
} : {}), {}, {
  isSoldOut: isSoldOut(activatedDraw)
});
const normalizeTeamWithGames = _ref => {
  let {
    payload
  } = _ref;
  const groupId = payload.groupId;
  const normalizedGames = (payload.containersWithDraw || []).map(containerWithDraw => normalizeGame(groupId, containerWithDraw));
  return _objectSpread(_objectSpread({
    id: groupId,
    name: payload.name,
    type: 'RETAILER',
    city: payload.city,
    membersCount: payload.numberOfMembers,
    games: normalizedGames,
    gamesAmount: normalizedGames.length,
    coverImage: payload.imageId ? {
      path: payload.imageId
    } : undefined
  }, payload.city ? {
    city: payload.city
  } : {}), payload.currentUserRoleInGroup ? {
    following: true
  } : {});
};
setGlobal('svs.components.marketplaceShared.coOpPlay.helpers.normalizeTeamWithGames', normalizeTeamWithGames);
setGlobal('svs.components.marketplaceShared.coOpPlay.helpers.normalizeGame', normalizeGame);

 })(window);