(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/co-op-play/assets/javascripts/middlewares/create-kibana-middleware.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/co-op-play/assets/javascripts/middlewares/create-kibana-middleware.js');
"use strict";



const {
  COOP_PLAY_FETCH_FAILURE,
  COOP_PLAY_FETCH_SUCCESS,
  COOP_PLAY_FETCH_NEXT_SUCCESS,
  COOP_PLAY_FETCH_NEXT_FAILURE,
  FRACTION_PAYMENT_SUCCESS,
  FRACTION_PAYMENT_FAILURE
} = svs.components.marketplaceShared.sharedConstants.constants;
const {
  log
} = svs.core;
const getErrorCode = errObj => {
  if (errObj && errObj.code) {
    return errObj.code;
  }
  return 0;
};
function createKibanaMiddleware() {
  const logger = new log.Logger('marketplace-co-op-client');
  const kibanaMiddleware = () => next => action => {
    var _action$other, _action$follow;
    const fetchError = (action === null || action === void 0 || (_action$other = action.other) === null || _action$other === void 0 ? void 0 : _action$other.error) || (action === null || action === void 0 || (_action$follow = action.follow) === null || _action$follow === void 0 ? void 0 : _action$follow.error);
    action.error = action.error || fetchError;
    const code = getErrorCode(action.error);
    try {
      switch (action.type) {
        case COOP_PLAY_FETCH_SUCCESS:
          logger.debug('✅ Successfully fetched co-cop shares');
          break;
        case COOP_PLAY_FETCH_FAILURE:
          logger.info("\u274C Failed to fetch co-cop shares (code: ".concat(code, ", message: ").concat(action.error.message, ")"));
          break;
        case COOP_PLAY_FETCH_NEXT_SUCCESS:
          logger.debug('✅ Successfully fetched next co-cop shares');
          break;
        case COOP_PLAY_FETCH_NEXT_FAILURE:
          logger.info("\u274C Failed to fetch next co-cop shares (code: ".concat(code, ", message: ").concat(action.error.message, ")"));
          break;
        case FRACTION_PAYMENT_SUCCESS:
          {
            logger.debug("\u2705 Successfully bought share (groupId: ".concat(action.groupId, " productId: ").concat(action.productId, " activatedDrawId: ").concat(action.activatedDrawId, " fractionsBought: ").concat(action.fractionsBought, ")"));
            break;
          }
        case FRACTION_PAYMENT_FAILURE:
          {
            logger.info("\u274C Failed to buy a share (groupId: ".concat(action.groupId, " productId: ").concat(action.productId, " groupId: ").concat(action.groupId, " code: ").concat(code, ", message: ").concat(action.error.message, ")"));
            break;
          }
        default:
      }
    } catch (e) {
      logger.error('Failed logging to kibana:', e);
    } finally {
      next(action);
    }
  };
  return kibanaMiddleware;
}
setGlobal('svs.components.marketplaceShared.coOpPlay.middlewares.createKibanaMiddleware', createKibanaMiddleware);

 })(window);