(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/co-op-play/assets/javascripts/services/fetch-groups.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/co-op-play/assets/javascripts/services/fetch-groups.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  cachedJupiterGet
} = svs.components.marketplaceData;
const {
  ActivatedDrawStatus
} = svs.components.marketplaceShared.coOpPlay.helpers.status;
const {
  normalizeTeamWithGames,
  queryFilter
} = svs.components.marketplaceShared.coOpPlay.helpers;
const showContainer = container => container.activatedDrawStatus === ActivatedDrawStatus.OPEN && (container.currentUserNumberOfShares > 0 || container.maxShares > container.soldShares);
const getActiveContainers = results => {
  const openContainers = results.map(r => _objectSpread(_objectSpread({}, r), {}, {
    containersWithDraw: r.containersWithDraw.filter(c => showContainer(c))
  }));
  return openContainers.filter(r => r.containersWithDraw.length > 0);
};
const CONTAINER_COUNT = 60;
const fetchGroups = (filters, offset, callback) => {
  const apiVersion = 3;
  let query = queryFilter(filters);
  query = offset ? query.concat("&offset=".concat(offset)) : query;
  query = query.concat('&onlyShowRetailers=true');
  query = query.concat("&containerCount=".concat(CONTAINER_COUNT));
  const url = "/marketplace/".concat(apiVersion, "/groups?").concat(query);
  fetchGroups.cacheRequest(url, response => {
    const result = response.groups || [];
    const count = result.length;
    const normalizedResult = getActiveContainers(result).map(r => normalizeTeamWithGames({
      payload: r
    }));
    callback(undefined, {
      games: normalizedResult,
      totalCount: response.totalHits,
      count
    });
  }, error => {
    callback(error.responseJSON.error);
  });
};
fetchGroups.cacheRequest = cachedJupiterGet.createCache(1000);
setGlobal('svs.components.marketplaceShared.coOpPlay.services.fetchGroups', fetchGroups);

 })(window);