(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/co-op-play/assets/javascripts/co-op-filter-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/co-op-play/assets/javascripts/co-op-filter-actions.js');
"use strict";


const {
  FILTER_UPDATE,
  FILTER_SET_PRODUCT,
  LOAD_QUERYSTRING
} = svs.components.marketplaceShared.sharedConstants.constants;
const {
  getFiltersFromQuery
} = svs.components.marketplaceShared.coOpPlay.helpers.queryString;
const updateFilterAction = updatedFilter => ({
  type: FILTER_UPDATE,
  updatedFilter
});
const setProductAction = (product, subProductId, productId) => ({
  type: FILTER_SET_PRODUCT,
  product,
  subProductId,
  productId
});
const loadFromQueryString = () => ({
  type: LOAD_QUERYSTRING,
  data: getFiltersFromQuery()
});
setGlobal('svs.components.marketplaceShared.coOpPlay.actions', {
  FILTER_UPDATE,
  updateFilterAction,
  FILTER_SET_PRODUCT,
  setProductAction,
  LOAD_QUERYSTRING,
  loadFromQueryString
});

 })(window);