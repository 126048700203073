(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/co-op-play/assets/javascripts/helpers/load-co-op-from-qs.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/co-op-play/assets/javascripts/helpers/load-co-op-from-qs.js');
"use strict";


const {
  loadFromQueryString
} = svs.components.marketplaceShared.coOpPlay.actions;
const loadCoOpData = store => {
  store.dispatch(loadFromQueryString());
};
setGlobal('svs.components.marketplaceShared.coOpPlay.helpers.loadCoOpData', loadCoOpData);

 })(window);