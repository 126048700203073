(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/co-op-play/assets/javascripts/middlewares/ga-middleware.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/co-op-play/assets/javascripts/middlewares/ga-middleware.js');
"use strict";


const {
  trackEvent
} = svs.components.analytics;
const {
  getProdName
} = svs.components.lbUtils.sportProducts;
const {
  FRACTION_PAYMENT_SUCCESS
} = svs.components.marketplaceShared.sharedConstants.constants;
const TYPE_FRACTION_PAYMENT_SUCCESS = 'TYPE_FRACTION_PAYMENT_SUCCESS';
const TYPE_FILTER_QUERY_NAME = 'TYPE_FILTER_QUERY_NAME';
const TYPE_FILTER_FRACTION_PRICE = 'TYPE_FILTER_FRACTION_PRICE';
const {
  FILTER_QUERY_NAME,
  FILTER_FRACTION_PRICE
} = svs.components.marketplaceShared.sharedConstants.constants;
const {
  FILTER_UPDATE
} = svs.components.marketplaceShared.coOpPlay.actions;
const getActionType = action => {
  if (action.type === FILTER_UPDATE) {
    var _action$updatedFilter, _action$updatedFilter2;
    if ((action === null || action === void 0 || (_action$updatedFilter = action.updatedFilter) === null || _action$updatedFilter === void 0 ? void 0 : _action$updatedFilter.key) === FILTER_FRACTION_PRICE) {
      return TYPE_FILTER_FRACTION_PRICE;
    }
    if ((action === null || action === void 0 || (_action$updatedFilter2 = action.updatedFilter) === null || _action$updatedFilter2 === void 0 ? void 0 : _action$updatedFilter2.key) === FILTER_QUERY_NAME) {
      return TYPE_FILTER_QUERY_NAME;
    }
    return null;
  }
  return null;
};
function buildEventFractionPayment(action, store) {
  const group = store.getState().CoOpPlayResults.data.find(group => group.id === action.groupId);
  const game = group.games.find(game => game.activatedDrawId === getActivatedDrawId(action));
  const productName = getProdName(game.productId);
  return {
    category: 'Andelsspel produktsida',
    action: 'Reserve share',
    opt_label: productName
  };
}
function buildEventFilterQueryName() {
  return {
    category: 'Andelsspel produktsida',
    action: 'Sök ombud'
  };
}
function buildEventFilterFractionPrice() {
  return {
    category: 'Andelsspel produktsida',
    action: 'Reglage'
  };
}
function getActivatedDrawId(action) {
  return action.activatedDrawId;
}
function handleAction(action, store) {
  const actionType = getActionType(action);
  let event;
  if (!actionType) {
    return;
  }
  if (actionType === TYPE_FRACTION_PAYMENT_SUCCESS) {
    event = buildEventFractionPayment(action, store);
  }
  if (actionType === TYPE_FILTER_QUERY_NAME) {
    event = buildEventFilterQueryName();
  }
  if (actionType === TYPE_FILTER_FRACTION_PRICE) {
    event = buildEventFilterFractionPrice();
  }
  trackEvent(event);
}
const gaMiddleware = store => next => action => {
  next(action);
  handleAction(action, store);
};
setGlobal('svs.components.marketplaceShared.coOpPlay.middlewares.gaMiddleware', gaMiddleware);

 })(window);