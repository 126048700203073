(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/co-op-play/assets/javascripts/services/fetch-results.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/co-op-play/assets/javascripts/services/fetch-results.js');
"use strict";


const {
  jupiter
} = svs.core;
const {
  normalizeTeamWithGames,
  postFilter,
  queryFilter
} = svs.components.marketplaceShared.coOpPlay.helpers;
const {
  PAGE_SIZE
} = svs.components.marketplaceShared.sharedConstants.constants;
const fetchResults = function (filter, offset, callback) {
  let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  let query = queryFilter(filter);
  query = offset ? query.concat("&offset=".concat(offset)) : query;
  query = query.concat("&count=".concat((options === null || options === void 0 ? void 0 : options.count) || PAGE_SIZE));
  const url = "/marketplace/1/groups/search?".concat(query);
  jupiter.get(url, response => {
    const result = response.result || [];
    const count = result.length;
    const normalizedResult = result.map(r => normalizeTeamWithGames({
      payload: r
    }));
    const filteredGames = postFilter(filter, normalizedResult);
    callback(undefined, {
      games: filteredGames,
      totalCount: response.totalCount,
      offset,
      count
    });
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceShared.coOpPlay.services.fetchResults', fetchResults);

 })(window);